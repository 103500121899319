import axios from 'axios';

export default class PrecioService {

	getPrecios() {
		//return axios.get('assets/layout/data/precios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/precios`;
		const url = `/api/precios`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createPrecios(precios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/precios`;
		const url = `/api/precios`;
		return axios.post(url,precios).then(res=>res.data.data);
	}
	
	updatePrecios(precios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/precios/${precios.id_precio}`;
		const url = `/api/precios/${precios.id_precio}`;
		return axios.put(url,precios).then(res=>res.data.data);
	}
	
	deletePrecios(precios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/precios/${precios.id_precio}`;
		const url = `/api/precios/${precios.id_precio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getPreciosExport() {
		//return axios.get('assets/layout/data/precios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/preciosexport`;
		const url = `/api/preciosexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}
<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Precio..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPrecios || !selectedPrecios.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="precios" v-model:selection="selectedPrecios" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} precios de servicios">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Precios de Servicios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_precio" header="Id" :sortable="true"></Column> -->
					<Column field="nombre" header="Servicio" :sortable="true"></Column>
                    <Column field="precio" header="Precio MXN" :sortable="true"  style="text-align: right !important;">
					
						<template #body="slotProps" style="text-align: right !important;">
                                   
							<div :class="preciostyle" style="text-align: right">
								 ${{formatCurrency(slotProps.data.precio)}}
							</div>
                        </template>
					</Column>
                    <Column field="plazo" header="Plazo [Días]" :sortable="true"></Column>
                    <Column  field="fecha_efectiva" header="F. Efectiva" :sortable="true">
						<template #body="slotProps" >
                                    {{computedDatesFormat(slotProps.data.fecha_efectiva)}}
                        </template>
					</Column>
					<Column field="activop" header="Estatus" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activop)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPrecio(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePrecio(slotProps.data)" />
						</template>
					</Column>
					
				</DataTable>

				<Dialog v-model:visible="precioDialog" :style="{width: '450px'}" header="Precio del Servicio" :modal="true" class="p-fluid">
					
					<div class="p-field">
						<label for="servicio">Servicio *</label>
						<Dropdown id="servicio" required="true" name="servicio" v-model="precio.id_servicio" :options="servicios" optionLabel="nombre" placeholder="Selecionar servicio ..." autofocus :class="{'p-invalid': submitted && !precio.id_servicio}" />
						<small class="p-invalid" v-if="submitted && !precio.id_servicio">El servicio es requerido</small>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="precio">Precio (MXP) *</label>
							<InputNumber id="precio" v-model="precio.precio" mode="decimal" currency="USD" locale="en-US" autofocus :class="{'p-invalid': submitted && !precio.precio}"/>
							<small class="p-invalid" v-if="submitted && !precio.precio">El precio es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="plazo">Plazo [Días]</label>
							<InputNumber id="plazo" v-model="precio.plazo" integeronly />
						</div>
					</div>

					<div class="p-field">
						<label for="fechaEfectiva">Fecha Efectiva *</label>
						<InputMask :disabled="disablefecha" mask="99/99/9999" v-model="precio.fecha_efectiva" placeholder="99/99/9999" slotChar="DD/MM/YYYY"  autofocus :class="{'p-invalid': submitted && !precio.fecha_efectiva}"/>
						<small class="p-invalid" v-if="submitted && !precio.fecha_efectiva">La fecha efectiva es requerida</small>
					</div>

					<div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true" v-model="precio.activop" />
                        <label for="activo">Activo</label>
                    </div>
					
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePrecio" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePrecioDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="precio">¿Desea eliminar el precio del servicio <b>{{precio.servicio}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePrecioDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deletePrecio" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePreciosDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="precio">¿Desea eliminar los precios de los servicios seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePreciosDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPrecios" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import PrecioService from '../service/PrecioService';
import ServicioService from '../service/ServicioService';
import moment from 'moment';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies

export default {
	data() {
		return {
			precios: null, 
			precioDialog: false,
			deletePrecioDialog: false,
			deletePreciosDialog: false,
			precio: {},
			selectedPrecios: null,
			filters: {},
			submitted: false,
			dropdownValues: [
				{nombre: 'Cultivo de Grasa'},
				{nombre: 'Cultivo de Médula Ósea'},
				{nombre: 'Cultivo de Sangre Periférica'},
				{nombre: 'Cultivo de Piel'},
				{nombre: 'Kit de Envío (Hielera)'}
			],
			servicios: null,
			serviciosPos:[],
			plazo:null,
			OptionServicio:null,
			disablefecha:false,
			PrecioExpor:null,
			useconver:null,
			userdata:null,
		}
	},
	precioService: null,
	servicioService: null,
	created() {
		this.precioService = new PrecioService();
		this.servicioService = new ServicioService();
	},
	mounted() {
		this.precioService.getPrecios().then(data =>{ this.precios = data; console.log(data);  });
		this.servicioService.getServicios().then(data => this.servicios = data);
		
	},
	methods: {
        formatCurrency(value) {
			// return value.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'});
			let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		openNew() {
			this.precio = {};
			this.submitted = false;
			this.precioDialog = true;
			this.disablefecha = false;
			var d = new Date();
 
			this.serviciosPos.push({
						"id_precio":0,
						"id_servicio":0,
						"precio":0,
						"plazo":0,
						"fecha_efectiva":this.computedDatesFormat(d),
						"activo":true
					}); 
					
					 this.precio=this.serviciosPos[0];
					 this.serviciosPos=[];
		},
		hideDialog() {
			this.precioDialog = false;
			this.submitted = false;

		},
		savePrecio() {
			this.submitted = true;          
			 
				if (this.precio.id_precio>0) {
					// this.$set(this.precios, this.findIndexById(this.precio.id), this.precio);
					this.serviciosPos.push({
						"id_precio":this.precio.id_precio,
						"id_servicio":this.precio.id_servicio.id_servicio,
						"precio":this.precio.precio,
						"plazo":this.precio.plazo,
						"fecha_efectiva":this.computedDatesFormatSave(this.precio.fecha_efectiva),
						"activo":this.precio.activop
					});

					console.log(this.serviciosPos);

					this.precioService.updatePrecios(this.serviciosPos[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Precio actualizada', life: 3000});					 
					this.serviciosPos=[];
					document.location.reload();
					this.precioDialog = false;
				}
				else {
					// this.precio.id = this.createId();
					// this.precios.push(this.precio);
					if(typeof this.precio.id_servicio.id_servicio != 'undefined'){

					

					if( typeof this.precio.plazo != 'undefined'){
						this.plazo=this.precio.plazo;
					}
					
					this.serviciosPos.push({
						"id_servicio":this.precio.id_servicio.id_servicio,
						"precio":this.precio.precio,
						"plazo":this.plazo,
						"fecha_efectiva":this.computedDatesFormatSave(this.precio.fecha_efectiva),
						"activo":this.precio.activop,
					});
					console.log(this.serviciosPos);
					 
					this.precioService.createPrecios(this.serviciosPos[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Precio creada', life: 3000});					 
					this.serviciosPos=[];
					this.precioDialog = false;
					document.location.reload();
					}else{
							// this.$toast.add({severity:'warn', summary: 'Inf', detail: 'Favor de seleccionar un servicio', life: 3000});	
							this.precioDialog = true;
					}
				}

				
				 
			 
		},
		editPrecio(precio) {
			this.precio = {...precio};
			this.precioDialog = true;
			this.disablefecha=true;
			this.OptionServicio=this.precio.id_servicio;
			this.precio.fecha_efectiva=this.computedDatesFormat(precio.fecha_efectiva)

			for(var i in this.servicios){
				if(this.OptionServicio==this.servicios[i].id_servicio){
					
					this.precio.id_servicio = this.servicios[i];
				}                  	
			} 
			
		},
		confirmDeletePrecio(precio) {
			this.precio = precio;
			this.deletePrecioDialog = true;
		},
		deletePrecio() {
			// this.precios = this.precios.filter(val => val.id !== this.precio.id);
			this.deletePrecioDialog = false;
			// this.precio = {};
			this.precioService.deletePrecios(this.precio).then(data =>{
					console.log(data);
					document.location.reload();
			});
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Precio eliminada', life: 3000});
			
		
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.precios.length; i++) {
				if (this.precios[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.precios.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deletePreciosDialog = true;
		},
		deleteSelectedPrecios() {
			this.precios = this.precios.filter(val => !this.selectedPrecios.includes(val));
			this.deletePreciosDialog = false;
			this.selectedPrecios = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Precios eliminadas', life: 3000});
		},
		turno(value){
			if(value ==1){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		computedDatesFormat(value) {
                return moment(value).format('DD/MM/YYYY')
		},
		computedDatesFormatSave(value) {
                return moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD');
		},
		download : function() {
			this.precioService.getPreciosExport().then(data => {
			this.PrecioExpor = data
				if(this.PrecioExpor != null){
				const data = XLSX.utils.json_to_sheet(this.PrecioExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoPrecio.xlsx')
				}
			});
	
		},

	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.precio-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .precio-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.precio-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
.preciostyle{
 ;
}
// .p-datatable .p-datatable-tbody > tr > td{

// }
</style>
